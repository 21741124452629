@import url('https://fonts.googleapis.com/css2?family=Secular+One&display=swap');
#headline {
  font-family: 'Secular One', sans-serif;
  color: black;
  font-size: 3rem;
}
@media only screen and (max-width: 768px) {
  #headline {
    font-size: 2rem;
  }
}

#headlineLogo {
  max-height: 3rem;
  max-width: 3rem;
}
@media only screen and (max-width: 768px) {
  #headlineContainer {
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
#headlineContainer {

}

/* Duolingo fade */
.fade-in-background {
  animation: fadeInBackground 1s ease-in-out forwards;
}

.green-background {
  background-color: rgba(137, 226, 25, 0.4);
}

@keyframes fadeInBackground {
  0% {
    background-color: white;
  }
  100% {
    background-color: rgba(137, 226, 25, 0.4);
  }
}